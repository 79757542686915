import "../../styles/layout/hero.scss";
import "../../styles/core/Animated.scss";
import logo from "../../img/header/logo.png";
import bodegonCerradura from "../../img/bodegon-cerradura.png";

const Hero = (
) => {


  return (
    // ========== HERO =============
    // ========== PRUEBA =============

    <section className='hero ' id='home'>
      <h2 className='hero__title  animated-title '>
        Te damos la bienvenida al portal web de Securitas Direct y Vodafone
      </h2>
      <img
        src={logo}
        title='Logo Securitas y Vodafone'
        alt='Logo Securitas y Vodafone'
        className='hero__img animated-title'
      />
      <p className='hero__subtitle animated-title'>
        La Alarma Securitas Direct con tecnología PreSense™,
        permite actuar antes de que una situación se convierta en un problema
      </p>
      <article className="hero__article">
        <h4  className="hero__article-title">Alarma para pisos con cerradura inteligente</h4>
        <img
        src={bodegonCerradura}
        title='Bodegon cerradura'
        alt='Bodegon cerradura'
        className='hero__article-img'
      />
      <a href="#cerradura" className="hero__article-button">Ver Video<i className="fa-solid fa-play hero__article-icon"></i></a>
      <p className="hero__article-new">nuevo</p>
      </article>
    </section>
  );
};
export default Hero;
