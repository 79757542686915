/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-unknown-property */

import "../../styles/layout/cerradura.scss";

import Title from "./title/Title";

import videoCerradura from "../../img/Alarma-con-cerradura-inteligente.mp4";      

// import iconPlay from "../../img/video/Play.png";
// import imgVideo from "../../img/video/video3D.jpg";

const Cerradura = () => {
  // ========== JSK =============

  return (
    // ========== HERO =============

    <section className='cerradura'  id="cerradura">
      <Title
        grey='Nueva alarma para pisos'
        black='con cerradura inteligente'
        parragraph='Una mejor protección frente a robos y ocupaciones'
      />

        <video src={videoCerradura}
         controls
         className="cerradura__iframe">
        </video>
    
    </section>
  );
};
export default Cerradura;
